<template>
    <div class="mainform">
    <!--  -->
    <div class="form">
        <el-form class="content bascform" ref="BasicForm" :model="form" label-width="130px">
            <div class="col8">
                <el-form-item label="单据编号">
                <span class="onlyText">
                    {{form.companyCode}}
                </span>
                </el-form-item>
            </div>
            <div class="col8">
                <el-form-item label="FA报告">
                    <el-button type="primary" size="mini" plain>上传附件</el-button>
                </el-form-item>
            </div>
        </el-form>
    </div>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'BackDialog',
  props: [],
  data: function () {
    return {
      form: {
        companyCode: '123'
      },
      descripform: {
        aaa: '111'
      },
      dialogShow: false
    }
  },
  mounted () {

  },
  created () {

  },
  methods: {
    // 保存
    preservation () {
      this.dialogShow = true
    },
    affirm () {

    },
    handleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
</style>
